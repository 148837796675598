import React, { useState, useEffect } from 'react';
import '../../css/TextArea.css';
import '../../css/OpenclassroomsSection.css';
import Oc from '../../Img/Oc.png';
import { Link } from 'react-router-dom';

function CelCompV() {


return (

     <div class="BoxOc2" >
        <div class="ContPresCom">
            <div class="ContPresCom">
                <h2>Compétences Validées</h2>

            </div>
        </div>
    </div>

    );
}
export default CelCompV;
