import React, { useState, useEffect } from 'react';
import Soon from '../Img/Soon.png'; // Importez l'image de la bannière de présentation
import '../css/soon.css';

function PresentationSoon() {

  const [ContSoonOpacity, setContSoonOpacity] = useState(0);
  const [ContSoonWidth, setContSoonWidth] = useState("0%");

  useEffect(() => {
    const timer = setTimeout(() => {
      setContSoonOpacity(1); // Mettre l'opacité à 1
      setContSoonWidth("100%");
    }, 100); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  return (
    <div class="cont">
     <div class="ContSoon" style={{ opacity: ContSoonOpacity, width: ContSoonWidth }}>
        <h2> En cours de développement. Reviens bientot !</h2>
        <img src={Soon} alt="Présentation" />
    </div>
    </div>
  );
}

export default PresentationSoon;
