import React, { useState, useEffect } from 'react';
import a1 from './Img/a1.png';
import a2 from './Img/a2.png';
import a3 from './Img/a3.png';
import a4 from './Img/a4.png';
import '../../../css/CelOcappImo.css';
import { Link } from 'react-router-dom';

function CelOcAppImo() {


  return (
    <div class="ContentBoxOc">

      <Link to="/OCa1" className="linkWrapper">
          <img src={a1} alt="Image de présentation du projet" />
          <h1>Créez une application web avec React</h1>
      </Link>

        <Link to="/OCa2" className="linkWrapper">
            <img src={a2} alt="Image de présentation du projet" />
            <h1>
            Optimisez le référencement d'un site
            </h1>
        </Link>

        <Link to="/OCa3" className="linkWrapper">
            <img src={a3} alt="Image de présentation du projet" />
            <h1>
            Améliorez l'interface d'un site avec des animations CSS
            </h1>
        </Link>

        <Link to="/OCa4" className="linkWrapper">
            <img src={a4} alt="Image de présentation du projet" />
            <h1>
            Implémentez le front-end d'une application avec React
            </h1>
        </Link>

    </div>
  );
}

export default CelOcAppImo;
