import React, { useState, useEffect } from 'react';
import './css/Banner.css'; // Importe le fichier CSS pour les styles du banner
import { useNavigate } from 'react-router-dom';
import Burger from './Img/burger.png';
import Logo from './Img/logo.png';
import ModalMenu from './ModalMenu'; // Importe la modal
function Banner({ children }) {

//_______________________________________________________________

  //fonction diminution banner
  window.addEventListener('scroll', () => {
  const banner = document.querySelector('.banner');
  const bannerPosition = banner.getBoundingClientRect().top;

  // Vérifie si la bannière est visible à l'écran
  if (bannerPosition < window.innerHeight) {
    banner.classList.add('active'); // Ajoute la classe active pour déclencher l'animation
  }
});

//_______________________________________________________________

  //fonction suppr center au scroll
 window.addEventListener('scroll', () => {
  const center = document.querySelector('.center');
  const centerPosition = center.getBoundingClientRect().top;

  // Vérifie si le .center est visible à l'écran
  if (centerPosition < window.innerHeight) {
    center.classList.add('active'); // Ajoute la classe active pour déclencher l'animation
  }
});

//_______________________________________________________________

 //fonction réouvre la banner si en haut
window.addEventListener('scroll', () => {
  const scrollPosition = window.scrollY;

  // Vérifie si l'utilisateur est revenu en haut de la page
  if (scrollPosition === 0) {
    // Retire la classe .active des éléments qui en ont besoin
    const activeElements = document.querySelectorAll('.active');
    activeElements.forEach(element => {
      element.classList.remove('active');
    });
  }
});

//_______________________________________________________________
 const navigate = useNavigate();
 const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    navigate('/');
  };

//_______________________________________________________________
const handleClick = () => {
    const modal = document.querySelector('.modal');
    modal.classList.remove('modal');
    modal.classList.toggle('modalVisible');
    const mcontent = document.querySelector('.modal-content');
    mcontent.classList.remove('modal-content');
    mcontent.classList.toggle('modal-contentVisible')
  };

//_______________________________________________________________

  window.addEventListener('load', () => {
  const banner = document.querySelector('.banner');
  banner.style.opacity = '1';
});


  return (
    <div className="banner">
     <div className="left">
        {/* IMG à gauche */}
        <img src={Burger} alt="menu" onClick={handleClick} />
      </div>
      <div className="center">
        {/* Utilisation de la prop children pour afficher le titre dynamiquement */}
        {children}
      </div>
      <div className="right">
        {/* Img à droite */}
       <img src={Logo} alt="Logo" onClick={handleScrollToTop} />
      </div>
      <ModalMenu />
    </div>
  );
}

export default Banner;
