import React, { useState, useEffect } from 'react';
import '../../../css/Presentation.css'; // Importez le fichier CSS pour les styles de la bannière de présentation
import '../../../css/TextArea.css';
import Oc from '../../../Img/Oc.png';

function BannerCompetences() {
  const [bannerOpacity, setBannerOpacity] = useState(0);
  const [contentBannerPres, setContentBannerPres] = useState('150%');

  useEffect(() => {
    setBannerOpacity(1);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentBannerPres('0%'); // Réinitialiser la marge après un certain délai
    }, 100); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  return (
    <div className="presentation-banner" style={{ opacity: bannerOpacity }}>
      <div className="ContentBannerPres" style={{ marginLeft: contentBannerPres }}>
        <div className="TextArea">
          <h2>Retrouve ici tous les anciens projets liés a ma formation OpenClassrooms</h2>
          <p>
            Fin 2023 j'ai eu l'occasion de participer à la formation diplomante d'OpenClassrooms. Tu peux retrouver ici le contenu de 4 des projets que j'ai menés.
          </p>
        </div>
         <img src={Oc} alt="logoOpenclassrooms" />
      </div>
    </div>
  );
}

export default BannerCompetences;
