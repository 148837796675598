import React, { useState, useEffect } from 'react';
import '../../../css/Presentation.css';
import '../../../css/Article.css';
import a1 from './Img/a1.png';
import git from './Img/git.png';
function BannerOCProjetsA1() {
  const [bannerOpacity, setBannerOpacity] = useState(0);
  const [contentBannerPres, setContentBannerPres] = useState('150%');

  useEffect(() => {
    setBannerOpacity(1);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentBannerPres('0%'); // Réinitialiser la marge après un certain délai
    }, 100); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  return (
    <div className="BannerArticle" style={{ opacity: bannerOpacity }}>
      <div className="ContentBannerPres" style={{ marginLeft: contentBannerPres }}>
        <div className="Article">

          <img src={a1} alt="Image de présentation du projet" class="imgOC"/>
          <div class="txt">
            <div class="contexte">

              <h2> Contexte </h2>

              <p>
                Le site de Kasa a été codé il y a maintenant plus de 10 ans en ASP.NET avec un code legacy important. Laura, la CTO, a donc lancé une refonte totale pour passer à une stack complète en JavaScript avec NodeJS côté back-end, et React côté front-end. Kasa en a également profité pour commander de nouvelles maquettes auprès de son designer habituel, qui est en freelance. Un gros chantier pour cette année !
              </p>

            </div>
            <div class="contexte">

              <h2> Taches à accomplir </h2>

              <p>
                Démarrer le projet React et développer l’ensemble de l’application, les composants React, les routes React Router, en suivant les maquettes Figma (responsives !) et toutes les infos que je te donne ci-dessous. Et ce avec un code de qualité !
              </p>
            </div>

             <div class="contexte">

              <h2>  Contraintes  </h2>

              <p>
                Pour le défilement des photos dans la galerie (composant Gallery) :<br/>
                Si l'utilisateur se trouve à la première image et qu'il clique sur "Image précédente", la galerie affiche la dernière image.<br/><br/>
                Inversement, quand l'image affichée est la dernière de la galerie, si l'utilisateur clique sur "Image suivante", la galerie affiche la première image.<br/><br/>
                S'il n'y a qu'une seule image, les boutons "Suivant" et "Précédent" ainsi que la numérotation n'apparaissent pas.<br/><br/>
                La galerie doit toujours rester de la même hauteur, celle indiquée sur la maquette Figma. Les images seront donc coupées et centrées dans le cadre de l’image.<br/>
                Collapse : Par défaut, les Collapses sont fermés à l'initialisation de la page.<br/><br/>
                Si le Collapse est ouvert, le clic de l'utilisateur permet de le fermer.<br/><br/>
                Inversement, si le Collapse est fermé, un clic permet de l'ouvrir.
              </p>
            </div>

             <div class="contexte">

              <h2> Accés au code de ma production </h2>
              <a href="https://gitlab.com/SolidRacoon/pj08.git"><img src={git} alt="Image de gitlab" class="imgGit"/>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerOCProjetsA1;
