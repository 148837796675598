import React, { useState, useEffect } from 'react';
import '../../../css/Presentation.css';
import '../../../css/Article.css';
import a2 from './Img/a2.png';
import git from './Img/git.png';
function BannerOCProjetsA2() {
  const [bannerOpacity, setBannerOpacity] = useState(0);
  const [contentBannerPres, setContentBannerPres] = useState('150%');

  useEffect(() => {
    setBannerOpacity(1);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentBannerPres('0%'); // Réinitialiser la marge après un certain délai
    }, 100); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  return (
    <div className="BannerArticle" style={{ opacity: bannerOpacity }}>
      <div className="ContentBannerPres" style={{ marginLeft: contentBannerPres }}>
        <div className="Article">

          <img src={a2} alt="Image de présentation du projet" class="imgOC"/>
          <div class="txt">
            <div class="contexte">

              <h2> Contexte </h2>

              <p>
                Vous êtes développeur freelance et vous décidez de proposer vos services d’optimisation SEO à de nouveaux clients.<br/><br/>
                Parmi vos prospects, il y a le site de Nina Carducci, une photographe dont vous a parlé l’un de vos amis.
              </p>

            </div>
            <div class="contexte">

              <h2> Taches à accomplir </h2>

              <p>
                Faire une optimisation globale du site tant sur les performances que sur le SEO.<br/><br/>
                Mettre en place le référencement local en utilisant Schema.org.<br/><br/>
                Ajouter les metas pour les réseaux sociaux.<br/><br/>
                Faire les modifications liées à l’accessibilité du site.<br/><br/>
                Produire un rapport d’optimisation présentant toutes vos actions et leur impact.
              </p>
            </div>

             <div class="contexte">

              <h2>  Contraintes  </h2>

              <p>
                Utiliser Lighthouse<br/><br/>
                Avoir un rapport a 90 ou  plus
              </p>
            </div>

             <div class="contexte">

              <h2> Accés au code de ma production </h2>
              <a href="https://gitlab.com/SolidRacoon/pj9v2.git"><img src={git} alt="Image de gitlab" class="imgGit"/>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerOCProjetsA2;
