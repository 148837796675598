import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Accueil from './Pages/Accueil';
import Competences from './Pages/Competences';
import ProjetsTermines from './Pages/ProjetsTermines';
import ProjetsEnCours from './Pages/ProjetsEnCours';
import VoirTousLesProjets from './Pages/TousLesProjets';
import Galerie from './Pages/Galerie';
import Contact from './Pages/Contact';
import ScrollToTop from './ScrollToTop';
import OCProjets from './Pages/ProjetTermine/OCProjets/OCProjets';
import OCa1 from './Pages/ProjetTermine/OCProjets/OCa1';
import OCa2 from './Pages/ProjetTermine/OCProjets/OCa2';
import OCa3 from './Pages/ProjetTermine/OCProjets/OCa3';
import OCa4 from './Pages/ProjetTermine/OCProjets/OCa4';
import Soon from './Soon';

function App() {
  return (
    <Router>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/competences" element={<Competences />} />
        <Route path="/projets-termines" element={<ProjetsTermines />} />
        <Route path="/projets-en-cours" element={<ProjetsEnCours />} />
        <Route path="/voir-tous-les-projets" element={<VoirTousLesProjets />} />
        <Route path="/Galerie" element={<Galerie />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/OCProjets" element={<OCProjets />} />
        <Route path="/OCa1" element={<OCa1 />} />
        <Route path="/OCa2" element={<OCa2 />} />
        <Route path="/OCa3" element={<OCa3 />} />
        <Route path="/OCa4" element={<OCa4 />} />
        <Route path="/Soon" element={<Soon />} />
      </Routes>
    </Router>
  );
}

export default App;
