import React, { useState, useEffect } from 'react';
import '../../css/Presentation.css'; // Importez le fichier CSS pour les styles de la bannière de présentation
import '../../css/Competences.css'; // Importez le fichier CSS pour les styles de la bannière de présentation
import '../../css/TextArea.css';

function BannerCompetences() {
  const [bannerOpacity, setBannerOpacity] = useState(0);
  const [contentBannerPres, setContentBannerPres] = useState('150%');

  useEffect(() => {
    setBannerOpacity(1);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentBannerPres('0%'); // Réinitialiser la marge après un certain délai
    }, 100); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  return (
    <div className="presentation-banner" style={{ opacity: bannerOpacity }}>
      <div className="ContentBannerPres" style={{ marginLeft: contentBannerPres }}>
        <div className="TextAreaCompetences">
          <h2>Un résumé des compétences que j'ai aquises au cours de mon parcous.</h2>
          <p>
            Tou trouveras ici un panorama des compétences liées, de pret ou de loin, à l'informatique au cours des années.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BannerCompetences;
