import React from 'react';
import './css/ModalMenu.css';
import { Link } from 'react-router-dom';

const handleClick2 = () => {
    const modal2 = document.querySelector('.modalVisible');
    modal2.classList.remove('modalVisible');
    modal2.classList.toggle('modal');
    const mcontent2 = document.querySelector('.modal-contentVisible');
    mcontent2.classList.remove('modal-contentVisible');
    mcontent2.classList.toggle('modal-content')
  };


function ModalMenu() {
  return (
    <div className="modal" onClick={handleClick2}>
      <div className="modal-content">
        <Link to="/">Accueil</Link>
        <br/>
        <Link to="/competences">Compétences</Link>
        <br/>
        <Link to="/Soon">Projets en Cours</Link>
        <br/>
        <Link to="/projets-termines">Projets terminés</Link>
        <br/>
        <Link to="/Soon">Galerie Photos/Vidéos</Link>
        <br/>
        <Link to="/Soon">Tous les projets</Link>
        <br/>
        <Link to="/Soon">Contact</Link>
      </div>
    </div>
  );
}
export default ModalMenu;
