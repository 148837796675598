import React, { useEffect, useState, } from 'react';
import Banner from '../../../Banner';
import BannerOCProjets from './BannerOCProjets';
import CelOcAppImo from './CelOcAppImo';
import { useLocation } from 'react-router-dom';


function OCProjets() {



  return (
<main>
        <Banner>
          <h1>OpenClassrooms</h1>
        </Banner>
         <div>
          <BannerOCProjets />
        </div>
        <div>
          <CelOcAppImo />
        </div>


</main>

  );
}

export default OCProjets;

