import React, { useState, useEffect } from 'react';
import './css/Home.css'; // Importe le fichier CSS pour les styles de la page d'accueil
import Banner from './Banner'; // Importe la bannière
import PresentationSoon from './Pages/PresentationSoon'; // Importe la bannière

function Soon() {

  return (
<body>
      <main>
          <Banner>
        <h1>En cours de développement</h1>
      </Banner>
        <PresentationSoon />
      </main>
</body>
  );
}


export default Soon;
