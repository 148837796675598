import React, { useEffect, useState, } from 'react';
import Banner from '../Banner';
import BannerProjetTermine from './ProjetTermine/BannerProjetTermine';
import { useLocation } from 'react-router-dom';
import OpenclassroomsSection from './ProjetTermine/Openclassrooms';

function ProjetTermine() {

useEffect(() => {
    const banner = document.querySelector('.banner');
    banner.style.opacity = '1';
    return () => {
      banner.style.opacity = '0'; // Réinitialiser l'opacité lorsque le composant est démonté
    };
  }, []);



  return (
<main>
        <Banner>
          <h1>Projets Terminés</h1>
        </Banner>
        <div>
          <BannerProjetTermine />
        </div>
          <div>
          <OpenclassroomsSection />
        </div>
</main>

  );
}

export default ProjetTermine;

