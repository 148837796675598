import React, { useState, useEffect } from 'react';
import '../../css/TextArea.css';
import '../../css/OpenclassroomsSection.css';
import { Link } from 'react-router-dom';
import html from './Img/html.png';
import css from './Img/css.png';
import js from './Img/js.png';
import Kate from './Img/Kate.png';
import lighthouse from './Img/lighthouse.png';
import log from './Img/log.png';
import nodejs from './Img/nodejs.png';
import npm from './Img/npm.png';
import react from './Img/react.png';
import git from './Img/git.png';

function CelCompD() {

const [showHTMLDetails, setShowHTMLDetails] = useState(false);
const [showCSSDetails, setShowCSSDetails] = useState(false);
const [showjsDetails, setShowjsDetails] = useState(false);
const [showKDetails, setShowKDetails] = useState(false);
const [showLiDetails, setShowLiDetails] = useState(false);
const [showLoDetails, setShowLoDetails] = useState(false);
const [showNoDetails, setShowNoDetails] = useState(false);
const [showNpDetails, setShowNpDetails] = useState(false);
const [showReDetails, setShowReDetails] = useState(false);
const [showGitDetails, setShowGitDetails] = useState(false);

return (

    <div class="BoxOc2" >
        <div class="ContPresCom">
            <div class="ContPresCom">
                <h2>Compétences dans le milieu du travail</h2>
                <h3>Lors de ma formation d'intégrateur web chez OpenClassrooms</h3>
                <div class="CMT">

                        <img src={html} alt="logohtml" onClick={() => setShowHTMLDetails(!showHTMLDetails)}/>
                        <p class="CDetail" id="html" style={{ display: showHTMLDetails ? 'flex' : 'none' }}>
                            HTML (HyperText Markup Language) est un langage de balisage utilisé pour créer et structurer le contenu des pages web en utilisant des balises et des attributs. Il définit la structure et le contenu des éléments d'une page web, permettant leur présentation et leur interactivité sur internet.
                        </p>

                        <img src={css} alt="logocss" onClick={() => setShowCSSDetails(!showCSSDetails)}/>
                         <p class="CDetail" id="CSS" style={{ display: showCSSDetails ? 'flex' : 'none' }}>
                            Le CSS (Cascading Style Sheets) est un langage de feuilles de style utilisé pour décrire la présentation visuelle des documents HTML et XML sur le web. Il permet de contrôler les aspects tels que la mise en page, les couleurs, les polices de caractères et les animations.
                        </p>

                        <img src={js} alt="logojs" onClick={() => setShowjsDetails(!showjsDetails)}/>
                        <p class="CDetail" id="js" style={{ display: showjsDetails ? 'flex' : 'none' }}>
                            JavaScript (JS) est un langage de programmation polyvalent souvent utilisé pour créer des applications web interactives. Il est interprété côté client et permet de manipuler le contenu des pages web et d'interagir avec les utilisateurs.
                        </p>

                        <img src={Kate} alt="logokate" onClick={() => setShowKDetails(!showKDetails)}/>
                        <p class="CDetail" id="K" style={{ display: showKDetails ? 'flex' : 'none' }}>
                            KDE Advanced Text Editor, ou Kate, est un éditeur de code source développé par la communauté de logiciels libres KDE. Il fait partie de la Compilation de logiciels KDE depuis la version 2.2, qui a été publiée pour la première fois en 2001.
                        </p>

                        <img src={lighthouse} alt="logoLighthouse" onClick={() => setShowLiDetails(!showLiDetails)}/>
                        <p class="CDetail" id="Li" style={{ display: showLiDetails ? 'flex' : 'none' }}>
                           Lighthouse est un outil open source et automatisé pour améliorer les performances, la qualité et la précision de vos applications web. Lors de l'audit d'une page, Lighthouse effectue une série de tests sur la page, puis génère un rapport sur la performance de la page. À partir de là, vous pouvez utiliser les tests échoués comme indicateurs pour savoir ce que vous pouvez faire pour améliorer votre application.
                        </p>

                        <img src={log} alt="logoLogseq" onClick={() => setShowLoDetails(!showLoDetails)} />
                        <p class="CDetail" id="Lo" style={{ display: showLoDetails ? 'flex' : 'none' }}>
                           Logseq est un outil pour organiser vos pensées et vos informations dans une base de données graphique. Vous pouvez écrire, réviser, mémoriser, structurer, décharger votre cerveau et créer vos propres processus avec les fonctionnalités et les plugins de Logseq.
                        </p>

                        <img src={nodejs} alt="logoNodeJS" onClick={() => setShowNoDetails(!showNoDetails)}/>
                        <p class="CDetail" id="No" style={{ display: showNoDetails ? 'flex' : 'none' }}>
                           Node.js est une plateforme de développement JavaScript côté serveur, basée sur le moteur JavaScript V8 de Chrome. Elle permet d'exécuter du code JavaScript côté serveur, facilitant ainsi la création d'applications web et d'APIs hautement évolutives et performantes.
                        </p>

                        <img src={npm} alt="logoNPM" onClick={() => setShowNpDetails(!showNpDetails)}/>
                        <p class="CDetail" id="Np" style={{ display: showNpDetails ? 'flex' : 'none' }}>
                           Npm est le gestionnaire de packages de Node.js, utilisé pour installer, gérer et partager des modules JavaScript réutilisables. Il permet aux développeurs d'accéder à une vaste bibliothèque de packages open-source pour simplifier le développement d'applications.
                        </p>


                        <img src={react} alt="logoReact" onClick={() => setShowReDetails(!showReDetails)}/>
                        <p class="CDetail" id="Re" style={{ display: showReDetails ? 'flex' : 'none' }}>
                           React est une bibliothèque JavaScript open-source utilisée pour la construction d'interfaces utilisateur interactives et dynamiques, basées sur des composants réutilisables, facilitant ainsi le développement d'applications web modernes.
                        </p>

                        <img src={git} alt="logoOpenclassrooms" onClick={() => setShowGitDetails(!showGitDetails)}/>
                        <p class="CDetail" id="Git" style={{ display: showGitDetails ? 'flex' : 'none' }}>
                           React est une bibliothèque JavaScript open-source utilisée pour la construction d'interfaces utilisateur interactives et dynamiques, basées sur des composants réutilisables, facilitant ainsi le développement d'applications web modernes.
                        </p>

                    </div>

                <h3>Lors de ma formation de responsable d'espace numérique</h3>
                <h3>Lors de mon emploi de technicien de maintenance informatique</h3>
                <h3>Lors de mon emploi de Conseillé numérique</h3>

            </div>
        </div>
    </div>

    );
}
export default CelCompD;


