import React, { useState, useEffect } from 'react';
import '../../../css/Presentation.css';
import '../../../css/Article.css';
import a3 from './Img/a3.png';
import git from './Img/git.png';
function BannerOCProjetsA3() {
  const [bannerOpacity, setBannerOpacity] = useState(0);
  const [contentBannerPres, setContentBannerPres] = useState('150%');

  useEffect(() => {
    setBannerOpacity(1);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentBannerPres('0%'); // Réinitialiser la marge après un certain délai
    }, 100); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  return (
    <div className="BannerArticle" style={{ opacity: bannerOpacity }}>
      <div className="ContentBannerPres" style={{ marginLeft: contentBannerPres }}>
        <div className="Article">

          <img src={a3} alt="Image de présentation du projet" class="imgOC"/>
          <div class="txt">
            <div class="contexte">

              <h2> Contexte </h2>

              <p>
                Développer un site “mobile first” qui répertorie les menus de restaurants gastronomiques.<br/><br/>

                En plus des systèmes classiques de réservation, les clients pourront composer le menu de leur repas pour que les plats soient prêts à leur arrivée.<br/><br/>

              </p>

            </div>
            <div class="contexte">

              <h2> Taches à accomplir </h2>

              <p>
                Répondre au brief créatif.<br/><br/>
                Le développement doit se faire en CSS avec Sass, sans JavaScript.<br/><br/>
                Les fichiers sources .scss ainsi que le code compilé CSS doivent être disponibles dans un ou plusieurs fichiers dédiés.<br/><br/>
                Le site devra être réalisé en adoptant le Mobile First, c’est-à-dire qu’il faudra d’abordréaliser l'intégration de la maquette mobile, puis tablette, et enfin l'intégration du responsive vers le desktop.<br/><br/>
                L’ensemble du site devra être responsive sur mobile, tablette et desktop.<br/><br/>
                Les pages devront passer la validation W3C en HTML et CSS sans erreur.<br/><br/>
                Le site doit être parfaitement compatible avec les dernières versions desktop de Chrome et Firefox.<br/><br/>
                Ajouter differentes annimations.
              </p>
            </div>

             <div class="contexte">

              <h2>  Contraintes  </h2>

              <p>
                Aucun framework ne devra être utilisé (comme Bootstrap par exemple).<br/><br/>
                Aucun code CSS ne doit être appliqué via un attribut style dans une balise HTML
              </p>
            </div>

             <div class="contexte">

              <h2> Accés au code de ma production </h2>
              <a href="https://github.com/SolidRacoon/pj04"><img src={git} alt="Image de gitlab" class="imgGit"/>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerOCProjetsA3;
