import React, { useEffect, useState, } from 'react';
import Banner from '../Banner';
import BannerCompetences from './Competences/BannerCompetences';
import { useLocation } from 'react-router-dom';
import CelCompD from './Competences/CelCompD';
import CelCompV from './Competences/CelCompV';
import CelCompA from './Competences/CelCompA';
import '../css/Competences.css';

function ProjetTermine() {

useEffect(() => {
    const banner = document.querySelector('.banner');
    banner.style.opacity = '1';
    return () => {
      banner.style.opacity = '0'; // Réinitialiser l'opacité lorsque le composant est démonté
    };
  }, []);



  return (
<main>
        <Banner>
          <h1>Compétences</h1>
        </Banner>
        <div>
          <BannerCompetences />
        </div>
        <div class="AllCel">
          <CelCompD />
          <CelCompV />
          <CelCompA />
        </div>
</main>

  );
}

export default ProjetTermine;

