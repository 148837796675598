import React, { useState, useEffect } from 'react';
import PresImg from './Img/PresImg.jpg'; // Importez l'image de la bannière de présentation
import './css/Presentation.css'; // Importez le fichier CSS pour les styles de la bannière de présentation
import './css/TextArea.css';

function Presentation() {
  const [bannerOpacity, setBannerOpacity] = useState(0);
  const [contentBannerPres, setContentBannerPres] = useState('150%'); // Initialiser la marge à 150%


  useEffect(() => {
    // Transition de l'opacité après un certain délai
    const timer = setTimeout(() => {
      setBannerOpacity(1);
    }, 150); // Délai en millisecondes (1000 ms = 1 seconde)

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  useEffect(() => {
    // Transition de la marge après un certain délai
    const timer = setTimeout(() => {
      setContentBannerPres('0%'); // Réinitialiser la marge après un certain délai
    }, 100); // Délai en millisecondes (1000 ms = 1 seconde)

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);


  return (
     <div className="presentation-banner" style={{ opacity: bannerOpacity }}>
      <div className="ContentBannerPres" style={{ marginLeft: contentBannerPres }}>
      <img src={PresImg} alt="Présentation" />
      <div class="TextArea">
        <h2>
          Salut à toi!
        </h2>
        <p>
          <br/>
            Tu trouveras ici toutes mes passions, mes explorations et mes expérimentations dans l'univers infini de l'informatique.
          <br/>
          Dans un esprit DIY, tel un raton laveur farfouillant dans les recoins les moins explorés, je déniche des trésors là où d'autres ne voient que des déchets. Mon objectif ? Transformer ces trouvailles en quelque chose de nouveau, d'utile ; bref, créer.
        </p>
        </div>
         <div class="TextArea">
        <h2>
          Je suis là pour travailler AVEC!
        </h2>
        <p>
         Je suis également là pour présenter mes capacités et proposer mes services. J'adore travailler avec d'autres passionnés, échanger des idées et participer pleinement à la création de projets inspirants. Que tu sois un amateur curieux, un professionnel chevronné ou simplement quelqu'un qui aime bidouiller, je suis toujours partant pour partager mes connaissances, apprendre de nouvelles choses et contribuer à des initiatives innovantes. Alors, n'hésite pas à me contacter si tu as des idées à partager ou si tu souhaites collaborer sur un projet ensemble!
        </p>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
