import React, { useState, useEffect } from 'react';
import '../../css/TextArea.css';
import '../../css/OpenclassroomsSection.css';
import Oc from '../../Img/Oc.png';
import { Link } from 'react-router-dom';

function OpenclassroomsSection() {

// animation apparition de la box OC
const [A, setA] = useState('0px');
useEffect(() => {
    const timer = setTimeout(() => {
      setA('40%'); // Réinitialiser la marge après un certain délai
    }, 1000); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);
useEffect(() => {
    const handleResize = () => {
        // Mettez à jour la valeur de A en fonction de la largeur de la fenêtre
        if (window.innerWidth < 1300) {
            setA('90%');
        } else {
            setA('40%');
        }
    };

    // Ajoutez l'écouteur d'événements pour suivre les changements de taille de la fenêtre
    window.addEventListener('resize', handleResize);

    // Nettoyez l'écouteur d'événements lors du démontage du composant
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);
// animation apparition du text
const [B, setB] = useState('0%');
useEffect(() => {
    const timer = setTimeout(() => {
      setB('100%'); // Réinitialiser la marge après un certain délai
    }, 1500); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

// retour en haut de la page
  const [position, setPosition] = useState(0); // État pour la position actuelle de la page

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Fait défiler la page vers le haut de façon fluide
  };



return (
    <Link to="/OCProjets" onClick={scrollToTop}>
    <div class="BoxOc" style={{ width: A }}>
        <img src={Oc} alt="logoOpenclassrooms" />
        <div class="ContPres" style={{ opacity: B }}>
            <div class="ContPres2">
                <h2>OpenClassrooms ✵</h2>
                <p>
                    Dans le cadre de ma formation d'intégrateur Web, j'ai eu l'occasion de travailler sur différents projets. Retrouve dans ce dossier les détails de quatre projets que j'ai pu réaliser !
                </p>
            </div>
        </div>
    </div>
    </Link>
    );
}
export default OpenclassroomsSection;

