import React, { useState, useEffect } from 'react';
import '../../../css/Presentation.css';
import '../../../css/Article.css';
import a4 from './Img/a4.png';
import git from './Img/git.png';
function BannerOCProjetsA4() {
  const [bannerOpacity, setBannerOpacity] = useState(0);
  const [contentBannerPres, setContentBannerPres] = useState('150%');

  useEffect(() => {
    setBannerOpacity(1);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentBannerPres('0%'); // Réinitialiser la marge après un certain délai
    }, 100); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  return (
    <div className="BannerArticle" style={{ opacity: bannerOpacity }}>
      <div className="ContentBannerPres" style={{ marginLeft: contentBannerPres }}>
        <div className="Article">

          <img src={a4} alt="Image de présentation du projet" class="imgOC"/>
          <div class="txt">
            <div class="contexte">

              <h2> Contexte </h2>

              <p>
                Vous venez d’intégrer Argent Bank comme développeur front-end.
                Argent Bank est une nouvelle banque en ligne qui souhaite percer dans le secteur bancaire.

              </p>

            </div>
            <div class="contexte">

              <h2> Taches à accomplir </h2>

              <p>

        Créer l’application web complète et responsive avec React. Comme point de départ, nous vous avons fourni le HTML statique et le CSS pour la page d'accueil, la page de connexion et la page de profil. <br/><br/>
        Utiliser Redux pour gérer le state de l'ensemble de l'application.<br/><br/>
        Ce que doit faire l’application (voir les détails pour chacune des fonctionnalités sur nos modèles de GitHub Issues) :<br/><br/>
        L'utilisateur peut visiter la page d'accueil.<br/><br/>
        L'utilisateur peut se connecter au système (et pouvoir gérer des erreurs si l’username ou mdp sont incorrects).<br/><br/>
        L'utilisateur peut se déconnecter du système.<br/><br/>
        L'utilisateur ne peut voir les informations relatives à son propre profil qu'après s'être connecté avec succès.<br/><br/>
        L’utilisateur ne peut pas modifier son nom ni son prénom, mais il peut modifier son pseudo.<br/><br/>
        Pour la phase 2 : Transactions, nous sommes encore en phase de conception. Nous mettons au point une fonctionnalité pour les transactions, qui doit pouvoir permettre aux utilisateurs :<br/><br/>
        de visualiser toutes leurs transactions pour le mois en cours, groupées par compte ;<br/><br/>
        de visualiser les détails d'une transaction dans une autre vue ;<br/><br/>
        d'ajouter, de modifier ou de supprimer des informations sur une transaction (on ne supprimera ou n'ajoutera pas de transaction).<br/><br/>
        Parmi les éléments clés à spécifier pour chaque endpoint de l’API, il faudra :<br/><br/>
        la méthode HTTP (ex. : GET, POST, etc.) ;<br/><br/>
        la route (ex. : /store/inventory) ;<br/><br/>
        la description de ce à quoi correspond l’endpoint (ex. : Retour de l'inventaire des animaux de compagnie) ;<br/><br/>
        les paramètres possibles pour tenir compte des différents scénarios (ex. : itemId (facultatif) = ID de l'article spécifique à demander à la base de données d'inventaire) ;<br/><br/>
        les différentes réponses avec les codes de réponse correspondants qui ont un sens pour cet endpoint (ex. : 404 : réponse d'erreur d'article inconnu).<br/><br/>


              </p>
            </div>

             <div class="contexte">

              <h2>  Contraintes  </h2>

              <p>
                Forker le Repo exsistant
              </p>
            </div>

             <div class="contexte">

              <h2> Accés au code de ma production </h2>
              <a href="https://github.com/SolidRacoon/ArgentBank-website.git"><img src={git} alt="Image de gitlab" class="imgGit"/>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerOCProjetsA4;
