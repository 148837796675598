import React, { useEffect, useState, } from 'react';
import Banner from '../../../Banner';
import BannerOCProjetsA2 from './BannerOCProjetsA2';
import { useLocation } from 'react-router-dom';


function OCa2() {

  return (
<main>
        <Banner>
          <h1>Créez une application web avec React</h1>
        </Banner>
         <div>
          <BannerOCProjetsA2 />
        </div>
</main>

  );
}

export default OCa2;
