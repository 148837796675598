import React, { useState, useEffect } from 'react';
import '../../css/Presentation.css'; // Importez le fichier CSS pour les styles de la bannière de présentation
import '../../css/TextArea.css';

function BannerCompetences() {
  const [bannerOpacity, setBannerOpacity] = useState(0);
  const [contentBannerPres, setContentBannerPres] = useState('150%');

  useEffect(() => {
    setBannerOpacity(1);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentBannerPres('0%'); // Réinitialiser la marge après un certain délai
    }, 100); // Modifier ce délai selon vos besoins

    return () => clearTimeout(timer); // Nettoyer le timer lors du démontage du composant
  }, []);

  return (
    <div className="presentation-banner" style={{ opacity: bannerOpacity }}>
      <div className="ContentBannerPres" style={{ marginLeft: contentBannerPres }}>
        <div className="TextArea">
          <h2>Retrouve ici tous les anciens projets.</h2>
          <p>
           Voici une liste non exhaustive des différents projets, professionnels ou non, sur lesquels j'ai eu l'occasion de travailler. Les projets ayant ce symbole : ✵ sont des projets ayant leur propre page dédiée qui les détaille si tu souhaites en avoir le détail complet.
          </p>
        </div>
        <div className="TextArea">
          <h2>Filtres des projets</h2>
          <p>
            Actuellement en cours de développement
          </p>
        </div>
      </div>
    </div>
  );
}

export default BannerCompetences;
