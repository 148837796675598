import React, { useEffect, useState, } from 'react';
import Banner from '../../../Banner';
import BannerOCProjetsA3 from './BannerOCProjetsA3';
import { useLocation } from 'react-router-dom';


function OCa3() {

  return (
<main>
        <Banner>
          <h1>Créez une application web avec React</h1>
        </Banner>
         <div>
          <BannerOCProjetsA3 />
        </div>
</main>

  );
}

export default OCa3;
