// Accueil.js
import React, { useState, useEffect } from 'react';
import '../css/Home.css'; // Importe le fichier CSS pour les styles de la page d'accueil
import Banner from '../Banner'; // Importe la bannière
import Presentation from '../Presentation'; // Importe la bannière

function Accueil() {

  return (
<body>
      <main>
          <Banner>
        <h1>Accueil</h1>
      </Banner>
        <Presentation />
      </main>
</body>
  );
}


export default Accueil;
